@font-face {
  font-family: "Yanone Kaffeesatz";
  src: local("YanoneKaffeesatz"), url(assets/fonts/YanoneKaffeesatz-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik"), url(assets/fonts//Rubik-Regular.ttf) format("truetype");
}

label.Mui-required:after {
  content: " *";
  color: red;
}

// https://jira.solteq.com/browse/SQSTRCOM-337
.MuiContainer-root.MuiContainer-maxWidthLg,
.sticky-header,
.footer-container {
  @media (max-width: 4000px) {
    max-width: 1880px !important;
  }

  @media (max-width: 2000px) {
    max-width: 1780px !important;
  }

  @media (max-width: 1600px) {
    max-width: 1380px !important;
  }
}

// Stylings for the mobile category menu
.MuiDrawer-root {
  .MuiAccordion-root {
    .category-title {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 200px;
    }

    .MuiAccordionSummary-root.level-2 {
      .MuiAccordionSummary-content {
        padding-left: 8px;
      }

      &:before {
        display: none;
      }
    }
  }
}

.MuiPickersCalendarHeader-switchHeader {
  .MuiIconButton-root:not(.Mui-disabled) {
    color: #000000;
  }
}

.MuiPickersCalendar-transitionContainer {
  .MuiIconButton-root:not(.MuiPickersDay-dayDisabled) {
    color: #000000;
  }

  .MuiIconButton-root.MuiPickersDay-daySelected {
    color: #fff;
    font-weight: 500;
    background-color: #2d9fcf;
  }

  .MuiIconButton-root.MuiPickersDay-dayDisabled {
    color: rgba(0, 0, 0, 0.38) !important;
  }

  .MuiIconButton-root.MuiPickersDay-daySelected {
    border-radius: 50% !important;
  }
}

.header-contact {
  color: white;
  padding-top: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid white;
}

.product-image {
  > img {
    max-height: 25em;

    @media (max-width: 440px) {
      max-height: 20em;
    }
  }

  > iframe {
    height: 80%;
    width: 80%;
  }
}

.MuiTypography-root.product-sku {
  margin: 0 !important;
  padding-bottom: 4px;
}

.product-attributes {
  .MuiFormGroup-root {
    flex-direction: row;
  }
}

#HEADER_MINI_CART_Popper {
  .mini-cart-container {
    width: 100%;
    max-height: 70vh;

    .close-minicart-button {
      position: absolute;
      right: 0;
      border: 0;
      max-width: 45px;
      min-width: 45px;
      background: transparent;
    }

    table.responsiveTable tbody {
      tr {
        display: flex;
      }
    }

    @media (max-height: 800px) {
      max-height: 70vh;
    }
  }

  .MuiAvatar-root {
    width: 120px;
    height: 120px;
  }
}

.order-item-table {
  .MuiTypography-body1 {
    font-size: 0.8rem;
  }

  &.MuiTableCell-root .MuiAvatar-root {
    width: 100px;
    height: 100px;
  }
  .MuiIconButton-root {
    margin-left: 8px;
    .MuiIconButton-label {
      position: relative;
      input[type="checkbox"] {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

.expanded-menu-sub-links {
  font-size: 0.82em !important;

  &.show-more {
    font-style: italic;
  }
}

.expanded-menu-bold {
  font-size: 1em !important;
}

.icon-text {
  display: flex;
  align-items: stretch;

  p,
  span {
    margin-left: 0.3em;
  }
}

.smaller-input {
  input,
  select {
    height: 35px !important;
  }
}

.StyledNumberInput.mobile input {
  @media (max-width: 440px) {
    min-width: 120px; // to make sure that whole number input is visible in mobile
    max-width: 160px;
  }
}

// Grid cells border styling
.checkout-cart .MuiTableCell-root.MuiTableCell-body:nth-child(n + 4) {
  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 20%;
    left: 0;
    height: 80%;
    border-right: 0.5px solid #b5beca;
  }
}

// Add ribbon ads stylings here. Give the same name as the attribute identifier.
.PreSale {
  .ribbon-bg {
    fill: #26598b !important;
    stroke: #26598b !important;
  }
  &.MuiChip-root {
    background-color: #26598b !important;
  }
}

.SuomiTradingNewProduct,
.New {
  .ribbon-bg {
    fill: #2e7d32 !important;
    stroke: #2e7d32 !important;
  }
  &.MuiChip-root {
    background-color: #2e7d32 !important;
  }
}

// Make sure that popuppy thigns are shown on top. Add here necessary classes etc.
div[role="tooltip"],
.searchBar-results {
  z-index: 21 !important;
}

.no-padding {
  padding: 0;
}

/*** quick 'n' dirty ui/responsivity fixes. ***/

/* Inline styles from customer's taken from MC */
.sivupalkki {
  margin-top: 30px;
  margin-right: 30px;
  margin-bottom: 50px;
}

.rivi_lista {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
}

.sarake_lista {
  -ms-flex: 35%; /* IE10 */
  flex: 35%;
  max-width: 35%;
}

.sarake_lista2 {
  -ms-flex: 45%; /* IE10 */
  flex: 45%;
  max-width: 45%;
  margin-left: 10%;
}

li a img {
  height: 25px;
  width: 25px;
  margin-top: 20px !important;
}

li a img.some {
  margin-left: 10px;
}

.rivi {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
  .yhteys {
    -ms-flex: 40%;
    flex: 40%;
    max-width: 40%;
    padding-left: 20px;
    margin-top: -20px;
  }
  .henkilokuva {
    -ms-flex: 33%;
    flex: 33%;
    max-width: 33%;
  }
}

@media only screen and (max-width: 600px) {
  .sarake_lista {
    -ms-flex: 100%; /* IE10 */
    flex: 100%;
    max-width: 100%;
  }

  .sarake_lista2 {
    -ms-flex: 100%; /* IE10 */
    flex: 100%;
    max-width: 100%;
    margin-left: 0%;
  }
  li a img {
    display: none;
  }
}

/* END inline styles from customer's taken from MC  */

/* Catalog view filter sidebar */
.product-filter {
  .MuiFormControlLabel-label {
    overflow: hidden;
  }
}

.MuiSnackbar-root {
  z-index: 9991400;
}

#OrderConfirmationPage .StyledIcon-root {
  width: auto;
}

#Yhteystiedot {
  .MuiGrid-root {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .rivi {
    .henkilokuva {
      margin: 8px;
      flex-basis: 270px;
      max-width: 270px;
      img {
        width: 100%;
        height: unset;
      }
    }
  }
}

#Yhteystiedot {
  .rivi {
    .kartta {
      iframe {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }
}

.react-numeric-input {
  min-width: 160px !important;
}

.product-card {
  .MuiBox-root > .MuiGrid-container {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 1319px) {
  .order-item-table {
    .MuiTableRow-root[level]:not(:first-child) {
      border-top: 1px solid #b5beca;
    }
    .MuiTableRow-root td[colspan][value]:not(.subrow) {
      display: flex;
      align-items: center;
    }
    button.MuiIconButton-root {
      padding: 0;
    }
    > div:first-child {
      padding: 0 16px;
    }
    table[style] {
      display: flex;
      max-width: 100%;
      flex-direction: column;
    }
    table {
      thead {
        position: relative;
        tr {
          display: inline-block;
          width: 100%;
          th {
            display: none;
            &:nth-child(2)[style] {
              display: inline-block !important;
              width: 100% !important;
              padding-bottom: 0 !important;
              margin-bottom: 0 !important;
              &:after {
                content: "Valitse kaikki tilausrivit";
              }
            }
          }
        }
      }
      tbody {
        max-width: 100%;
        min-width: 100%;
        display: inline-block;
        tr {
          &:not(:first-child) {
            margin-top: 16px;
          }
          padding-bottom: 16px;
          max-width: 100%;
          display: flex;
          flex-wrap: wrap;
          min-width: 100%;
          width: 100%;
          & > :not(.paddingNone) {
            min-width: 100%;
            padding: 0 !important;
            flex-basis: 100%;
          }
          .MuiTableCell-alignLeft,
          .MuiTableCell-alignRight {
            margin-top: 16px;

            .MuiGrid-spacing-xs-2.MuiGrid-wrap-xs-nowrap {
              justify-content: flex-start;
            }
          }
          .MuiGrid-align-items-xs-center {
            align-items: flex-start;
          }
          .MuiFormControl-root {
            align-items: flex-start;
            width: 100%;
          }
          .MuiTableCell-body:nth-child(n + 4)::after {
            border: none !important;
          }
        }
      }
    }
  }
}

.shipment-group-summary-text {
  flex-direction: column;
  width: 100%;
  > div {
    width: 100%;
    .MuiInputBase-root {
      width: 100%;
      max-width: 600px;
    }
  }
}

#my-account-b2b {
  .MuiGrid-root.MuiGrid-container .MuiGrid-item > a {
    height: 100%;
    > .MuiPaper-root {
      height: 100%;
    }
  }
}

@media screen and (max-width: 959px) {
  .sivupalkki {
    margin-right: 0px !important;
  }

  .sarake_lista,
  .sarake_lista2 {
    min-width: 100% !important;
  }
  .sarake_lista2 {
    margin-left: 0 !important;
  }
  .product-listing-container {
    .MuiGrid-grid-xs-6 {
      max-width: 100% !important;
      flex-basis: 100% !important;
    }
  }
  .MuiGrid-container .left-margin-6 {
    margin-left: 0 !important;
  }
  .header-menu {
    .menu-container {
      .MuiAccordionDetails-root {
        padding-right: 0 !important;
        .MuiTypography-root {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .MuiMenuItem-root {
        padding-right: 0;
        padding-left: 0;
      }
      .MuiPaper-root.MuiAccordion-root {
        &::before {
          background-color: transparent;
        }
        .MuiButtonBase-root.MuiAccordionSummary-root {
          width: 100%;
          padding-right: 0;
          padding-left: 0;
          &.level-2 {
            display: inline-flex;
            padding: 0;
            .MuiAccordionSummary-content {
              padding-left: 0px;
            }
          }
        }
      }
    }
  }

  #Yhteystiedot {
    .rivi {
      .kartta {
        flex: 100%;
        max-width: 100%;
        iframe {
          width: 100% !important;
          max-width: 100% !important;
        }
      }
      .yhteys {
        max-width: 100%;
        padding-left: 0;
      }
    }
  }

  .qty-price-section {
    flex-basis: 50% !important;
  }
}

@media screen and (max-width: 700px) {
  #HEADER_MINI_CART_Popper {
    table.responsiveTable tbody {
      td {
        &.pivoted {
          display: flex !important;
          flex-direction: column !important;
          padding-left: 8px !important;
        }
        .tdBefore {
          position: relative !important;
          left: unset !important;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .header-menu[style] {
    z-index: 999998 !important;
  }

  #Yhteystiedot {
    .rivi {
      .kartta {
        margin-bottom: 16px;
      }
    }
  }
  .rivi_kategoria {
    .sarake_kategoria {
      flex: 33.33%;
      max-width: 33.33%;
    }
  }
  .page:not(.MuiContainer-root) {
    padding: 0 !important;
  }
  #HEADER_MINI_CART_Popper[style] {
    transform: translate3d(0, 63px, 0px) !important;
    left: unset !important;
    right: 5px !important;
  }
  div[cid="address-book"] {
    > * {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      > .MuiGrid-container {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        padding: 0;
        > .MuiGrid-container {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 460px) {
  #my-account-b2b {
    .responsiveTable tbody td.pivoted {
      display: flex;
      flex-direction: column;
      > * {
        align-items: center;
      }
    }
  }
  .rivi_kategoria {
    .sarake_kategoria {
      flex: 50%;
      max-width: 50%;
    }
  }
  .rivi {
    .henkilokuva {
      max-width: 100%;
    }
  }
}

@media screen and (min-width: 460px) {
  .product-listing-container {
    .MuiGrid-grid-sm-4 {
      max-width: 50% !important;
      flex-basis: 50% !important;
    }
  }
}

@media screen and (min-width: 1100px) {
  .product-listing-container {
    .MuiGrid-grid-sm-4 {
      max-width: 33.33% !important;
      flex-basis: 33.33% !important;
    }
  }
}

@media screen and (min-width: 1601px) {
  .product-listing-container {
    .MuiGrid-grid-sm-4 {
      max-width: 25% !important;
      flex-basis: 25% !important;
    }
  }
}

/*** END quick 'n' dirty ui/responsivity fixes. ***/

.mini-cart-table {
  .qty-price-section {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    &.quantity-cell {
      > * {
        min-width: 100%;
      }
    }
    &.price-cell {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      > .MuiTypography-root {
        &.vat-text {
          margin-left: 8px;
          font-size: 10px;
        }
        white-space: nowrap;
      }
    }
  }
}
